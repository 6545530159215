/** @format */

import { Card, Checkbox } from '@shopify/polaris'
import { useEffect, useRef, useState } from 'react'

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

//const LineChart = ({ eventData, title, chartData, yAxisLabel, uiOptions, chartComponentRef, showLegend = false, height = 650 }) => {
const LineChart = ({
    eventDataHeader,
    title,
    chartData,
    yAxisLabel,
    uiOptions,
    chartComponentRef,
    additionalInfo,
    showLegend = false,
    height = 620
}) => {
    const [seriesData, setSeriesData] = useState([])
    const [isOverflow, setIsOverflow] = useState(false)
    const [muteIsOverflow, setMuteIsOverflow] = useState(false)

    useEffect(() => {
        if (chartData.length > 200) {
            setIsOverflow(true)
        } else {
            setSeriesData(chartData)
        }
    }, [chartData])

    useEffect(() => {
        if (muteIsOverflow) {
            setSeriesData(chartData)
        }
    }, [chartData, muteIsOverflow])

    const options = {
        title: {
            text: title
        },
        tooltip: {
            split: false,
            formatter: function (a) {
                //console.log(this)
                const tzOffset = new Date().getTimezoneOffset()
                const tzOffsetSign = tzOffset > 0 ? '-' : '+'
                const tz = 'GMT' + tzOffsetSign + Math.abs(tzOffset) / 60

                let label = `${this.series.name}<br>X = ${new Date(this.x).toLocaleString() + ' ' + tz
                    }<br>Y = ${this.y}`
                if (
                    this.point.additionalInfo &&
                    this.point.additionalInfo.label
                ) {
                    label += '<br>Details: ' + this.point.additionalInfo.label
                }
                return label
            }
        },
        navigator: {
            enabled: true
        },
        scrollbar: {
            enabled: false
        },
        rangeSelector: {
            buttons: [
                {
                    type: 'month',
                    count: 1,
                    text: '1m'
                },
                {
                    type: 'month',
                    count: 3,
                    text: '3m'
                },
                {
                    type: 'month',
                    count: 6,
                    text: '6m'
                },
                {
                    type: 'all',
                    text: 'All'
                }
            ]
        },
        plotOptions: {
            series: {
                dataGrouping: {
                    enabled: false
                },
                animation: false,
                enableMouseTracking: true,
                states: {
                    hover: {
                        enabled: true,
                        brightness: 0.5
                    },
                    inactive: {
                        opacity: 0.2
                    }
                }
            }
        },
        legend: { enabled: showLegend, maxHeight: 118 },
        chart: {
            height: height,
            //animation: { duration: 220 }
            animation: false
        },
        xAxis: {
            type: 'datetime',
            ordinal: false
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: yAxisLabel
            },
            min: 0,
            gridLineColor: 'hsl(0, 0%, 94%)'
        },
        series: seriesData,
        accessibility: { enabled: false }
    }

    useEffect(() => {
        if (
            !eventDataHeader ||
            !chartComponentRef ||
            !chartComponentRef.current ||
            !chartComponentRef.current.chart
        ) {
            return
        }

        const chart = chartComponentRef.current.chart

        let left = chart.xAxis[0].getExtremes().dataMin

        if (eventDataHeader.public_sale_start_date_utc) {
            left = new Date(
                eventDataHeader.public_sale_start_date_utc
            ).getTime()
        }

        if (eventDataHeader.presale_start_date_utc) {
            left = new Date(eventDataHeader.presale_start_date_utc).getTime()
        }

        let right = moment(eventDataHeader.event_date_utc).toDate().getTime()

        function setExtremes() {
            if (chart.xAxis.length) {
                chart.xAxis[0].setExtremes(left, right)
            }
        }

        // Seems like the chart sometimes needs a bit of time to render before we can the extremes
        setExtremes()
        setTimeout(setExtremes, 1000)
        setTimeout(setExtremes, 3000)
    }, [seriesData, eventDataHeader, chartComponentRef])

    return (
        (isOverflow && (!muteIsOverflow)) ?
            <>
                <div>{title}: chart data overflow ({chartData.length} time-series)</div>
                <div style={{ marginTop: 8 }}><button onClick={() => { setMuteIsOverflow(true) }}>Render anyway</button></div>
            </>
            :
            <Card sectioned>
                <>
                    <div style={{ position: 'relative', padding: '18px 12px' }}>
                        <div
                            style={{
                                position: 'relative',
                                marginBottom: 8,
                                opacity: 0.88
                            }}
                            className="wtt-chart-label"
                        >
                            {uiOptions}
                        </div>

                        <HighchartsReact
                            ref={chartComponentRef}
                            highcharts={Highcharts}
                            constructorType={'stockChart'}
                            options={options}
                        />

                    </div>
                    {additionalInfo}
                </>
            </Card>
    )
}

export default LineChart
