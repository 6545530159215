import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack, TextField } from '@shopify/polaris'
import { useNavigate, useParams } from 'react-router-dom'

import { AgGridReact } from 'ag-grid-react'
import _ from 'lodash'
import { apiGet } from '../../Api'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'

const StubHubVenueBrowser = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [rowData, setRowData] = useState(null)
    const [filteredRowData, setFilteredRowData] = useState([])
    const [quickFilterText, setQuickFilterText] = useState('')
    const [selectedVenue, setSelectedVenue] = useState(null)

    useEffect(() => {
        async function fetchData() {
            const response = await apiGet('/stubhub/allVenues')
            setRowData(response)
            setIsLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (Array.isArray(rowData)) {
            const filteredRows = rowData.filter((row) =>
                JSON.stringify(row)
                    .toLowerCase()
                    .includes(quickFilterText.toLowerCase())
            )
            setFilteredRowData(filteredRows)
        } else {
            setFilteredRowData([])
        }
    }, [rowData, quickFilterText])

    const columnDefs = [
        {
            headerName: 'ID',
            field: 'id',
            sortable: true,
            filter: true,
            width: 150
        },
        {
            headerName: 'Venue Name',
            field: 'venue_name',
            sortable: true,
            filter: true,
            width: 300
        },
        {
            headerName: 'City',
            field: 'venue_city',
            sortable: true,
            filter: true
        },
        {
            headerName: 'State/Province',
            field: 'venue_state_province_name',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Country',
            field: 'venue_country',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Active',
            field: 'active',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Created At',
            field: 'created_at',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Updated At',
            field: 'updated_at',
            sortable: true,
            filter: true
        },
        {
            headerName: 'URL',
            field: 'venue_url',
            cellRenderer: (params) => (
                <a
                    href={params.value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Link
                </a>
            ),
            sortable: true,
            filter: true
        }
    ]

    return (
        <div style={{ height: '100%', width: '100%', padding: 8 }}>
            <div style={{ marginBottom: 4, padding: 4 }}>
                <Stack alignment="center">
                    <span>StubHub Venues</span>

                    <Stack.Item fill>
                        <TextField
                            labelHidden
                            aliign="right"
                            value={quickFilterText}
                            onChange={(t) => {
                                setQuickFilterText(t)
                            }}
                            placeholder="Quick Search"
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <span
                        style={{
                            opacity: 0.3,
                            marginRight: 60,
                            marginLeft: 40
                        }}
                    >
                        Click on columns for more precise filtering and sorting
                    </span>
                </Stack>
            </div>
            {isLoading && <Spinner size="large" color="teal" />}
            {!isLoading && (
                <div
                    className="ag-theme-alpine"
                    style={{ height: '90%', width: '100%' }}
                >
                    <AgGridReact
                        rowData={filteredRowData}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            resizable: true
                        }}
                        rowSelection="single"
                        onSelectionChanged={(e) => {
                            const rows = e.api.getSelectedRows()
                            if (rows.length === 1) {
                                setSelectedVenue(rows[0])
                            } else {
                                setSelectedVenue(null)
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export { StubHubVenueBrowser }
