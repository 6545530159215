import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { BsLayoutSidebarReverse, BsViewStacked } from 'react-icons/bs'
import { Card, Checkbox, Layout, Page, Spinner } from '@shopify/polaris'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Stack, TextField } from '@shopify/polaris'
import {
    assembleInventoryChartData,
    assembleMinPriceChartData,
    assemblePriceChartData
} from './ChartDataBuilder'
import { useNavigate, useParams } from 'react-router-dom'

import { AgGridReact } from 'ag-grid-react'
import LineChart from './LineChart'
import _ from 'lodash'
import { apiGet } from '../../Api'
import axios from 'axios'
import moment from 'moment'
import { useCookies } from 'react-cookie'

/*
 [{
    "eventID": "602843",
    "fetched_at": "2024-11-07T21:02:28.547Z",
    "sectionLabel": "Booths",
    "sectionID": "3",
    "isGeneralAdmission": 0,
    "amount": 12,
    "price": 16300
}]
*/
const AxsEventViewer = () => {
    const { eventID } = useParams()
    const [eventData, setEventData] = useState(null)
    const [eventHistory, setEventHistory] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [inventoryChartData, setInventoryChartData] = useState([])
    const [priceChartData, setPriceChartData] = useState([])
    const [minPriceChartData, setMinPriceChartData] = useState([])
    const [isMinPriceChartYAxisQuantity, setIsMinPriceChartYAxisQuantity] =
        useState(false)

    useEffect(() => {
        async function fetchData() {
            const response = await apiGet('/axs/event', { eventID })
            console.log('event data:', response)
            if (response) {
                if (response.meta) {
                    delete response.meta.lastSeenJSON
                }
            }

            response.historical.forEach((h) => {
                h.price = Math.floor(h.price / 100)
                // console.log('event data:', h)
            })

            setEventData(response.meta)
            setEventHistory(response.historical)

            setInventoryChartData(
                assembleInventoryChartData(response.historical)
            )

            setPriceChartData(assemblePriceChartData(response.historical))

            setMinPriceChartData(
                assembleMinPriceChartData(
                    response.historical,
                    isMinPriceChartYAxisQuantity
                )
            )

            setIsLoading(false)
        }

        fetchData()
    }, [eventID, isMinPriceChartYAxisQuantity])

    const d = eventData

    if (isLoading) {
        return <Spinner />
    }

    const detailsCard = (
        <Card title="Event Details" sectioned>
            <div>Event ID: {d.id}</div>
            <div>Event Date: {d.doorDateTimeUTC}</div>
            <div>Presale Start: {d.presaleDateTimeUTC}</div>
            <div>Presale End: {d.presaleEndDateTimeUTC}</div>
            <div>OnSale Start: {d.onsaleDateTimeUTC}</div>
            <div>
                <a
                    href={d.ticketing_ticketURL}
                    target="_blank"
                    rel="noreferrer"
                >
                    Ticketing Link
                </a>
            </div>
        </Card>
    )

    const inventoryChartCard = (
        <Card sectioned>
            <div>
                <LineChart
                    title="Inventory by Section"
                    chartData={inventoryChartData}
                    showLegend={true}
                />
            </div>
            {false && (
                <div className="mono">
                    {JSON.stringify(eventHistory, null, 2)}
                </div>
            )}
        </Card>
    )

    const priceChartCard = (
        <Card sectioned>
            <div>
                <LineChart
                    title="Inventory by Price and Section"
                    chartData={priceChartData}
                    showLegend={true}
                />
            </div>
        </Card>
    )

    const minPriceChartOptions = (
        <span style={{ marginLeft: 18, position: 'relative', top: 4 }}>
            <Checkbox
                label="Y-axis: Quantity"
                checked={isMinPriceChartYAxisQuantity}
                onChange={() =>
                    setIsMinPriceChartYAxisQuantity(
                        !isMinPriceChartYAxisQuantity
                    )
                }
            />
        </span>
    )

    const minPriceChartCard = (
        <Card sectioned>
            <div>
                <LineChart
                    title="Min Price by Section"
                    chartData={minPriceChartData}
                    showLegend={true}
                    uiOptions={minPriceChartOptions}
                />
            </div>
        </Card>
    )

    return !eventID ? (
        <div>Please provide an event id</div>
    ) : (
        <Page
            title={`${d?.eventTitle} / ${d?.venueTitle} / ${moment(
                d?.doorDateTimeUTC
            ).format('MM/DD/YY')}`}
            fullWidth
        >
            {detailsCard}
            {inventoryChartCard}
            {priceChartCard}
            {minPriceChartCard}

            {false && (
                <Card title="Data Dump" sectioned>
                    <div className="mono">
                        {JSON.stringify(eventData, null, 2)}
                    </div>
                </Card>
            )}
        </Page>
    )
}

export { AxsEventViewer }
