/** @format */

import {
    ActionList,
    AppProvider,
    Card,
    ContextualSaveBar,
    FooterHelp,
    FormLayout,
    Frame,
    Layout,
    Loading,
    Modal,
    Navigation,
    Page,
    TextField,
    Toast,
    TopBar
} from '@shopify/polaris'
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi'
import {
    DataVisualizationMajor,
    DiscountCodeMajor,
    FinancesMinor,
    HintMajor,
    LinkMinor,
    LiveViewMajor,
    LogOutMinor,
    MarketsMajor,
    ShipmentMajor,
    WandMajor
} from '@shopify/polaris-icons'
import { useCallback, useRef, useState } from 'react'

import ActiveTrader from './Pages/ActiveTrader'
import logoPNG from './logo.png'
import { useFirebaseContext } from './FirebaseContext'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

/**
 * index >> App >> MainApp >> AppFrame
 */
function AppFrame({ children }) {
    const { logOut } = useFirebaseContext()

    const defaultState = useRef({
        emailFieldValue: 'dharma@jadedpixel.com',
        nameFieldValue: 'Jaded Pixel'
    })
    const skipToContentRef = useRef(null)

    const [isFullscreen, setIsFullscreen] = useState(false)

    const [toastActive, setToastActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [searchActive, setSearchActive] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [nameFieldValue, setNameFieldValue] = useState(
        defaultState.current.nameFieldValue
    )
    const [emailFieldValue, setEmailFieldValue] = useState(
        defaultState.current.emailFieldValue
    )
    const [storeName, setStoreName] = useState(
        defaultState.current.nameFieldValue
    )
    const [supportSubject, setSupportSubject] = useState('')
    const [supportMessage, setSupportMessage] = useState('')

    const handleSubjectChange = useCallback(
        (value) => setSupportSubject(value),
        []
    )
    const handleMessageChange = useCallback(
        (value) => setSupportMessage(value),
        []
    )
    const handleDiscard = useCallback(() => {
        setEmailFieldValue(defaultState.current.emailFieldValue)
        setNameFieldValue(defaultState.current.nameFieldValue)
        setIsDirty(false)
    }, [])
    const handleSave = useCallback(() => {
        defaultState.current.nameFieldValue = nameFieldValue
        defaultState.current.emailFieldValue = emailFieldValue

        setIsDirty(false)
        setToastActive(true)
        setStoreName(defaultState.current.nameFieldValue)
    }, [emailFieldValue, nameFieldValue])
    const handleNameFieldChange = useCallback((value) => {
        setNameFieldValue(value)
        value && setIsDirty(true)
    }, [])
    const handleEmailFieldChange = useCallback((value) => {
        setEmailFieldValue(value)
        value && setIsDirty(true)
    }, [])
    const handleSearchResultsDismiss = useCallback(() => {
        setSearchActive(false)
        setSearchValue('')
    }, [])
    const toggleToastActive = useCallback(
        () => setToastActive((toastActive) => !toastActive),
        []
    )
    const toggleMobileNavigationActive = useCallback(
        () =>
            setMobileNavigationActive(
                (mobileNavigationActive) => !mobileNavigationActive
            ),
        []
    )
    const toggleModalActive = useCallback(
        () => setModalActive((modalActive) => !modalActive),
        []
    )

    const toastMarkup = toastActive ? (
        <Toast onDismiss={toggleToastActive} content="Changes saved" />
    ) : null

    const contextualSaveBarMarkup = isDirty ? (
        <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
                onAction: handleSave
            }}
            discardAction={{
                onAction: handleDiscard
            }}
        />
    ) : null

    const topBarMarkup = false && (
        <TopBar
            showNavigationToggle
            searchResultsVisible={searchActive}
            onSearchResultsDismiss={handleSearchResultsDismiss}
            onNavigationToggle={toggleMobileNavigationActive}
        />
    )
    const navigate = useNavigate()

    const host = window.location.host
    const isLocalhost = host.includes('localhost') || host.includes('127.0.0.1')

    const isActiveTrader = window.location.pathname.startsWith('/activeTrader')
    if (isActiveTrader && !isFullscreen) {
        setIsFullscreen(true)
    }

    const navigationMarkup = (
        <Navigation location="/">
            <div>
                <img
                    src={logoPNG}
                    alt="WeTurnTix"
                    style={{
                        marginLeft: '2em',
                        marginBottom: '1em',
                        opacity: 0.8
                    }}
                />
            </div>
            <Navigation.Section
                title="TicketMaster"
                items={[
                    {
                        label: 'Events',
                        onClick: () => {
                            navigate('/ticketMaster/search')
                        }
                    },
                    {
                        label: 'Alerts',
                        onClick: () => {
                            navigate('/ticketMaster/alertsPage')
                        }
                    },
                    {
                        label: 'Bulk Exports',
                        onClick: () => {
                            navigate('/ticketMaster/bulkExports')
                        }
                    },
                    {
                        label: 'Status Page',
                        onClick: () => {
                            navigate('/ticketMaster/statusPage')
                        }
                    },
                    {
                        label: 'Live Inventory',
                        onClick: () => {
                            navigate('/ticketMaster/livePage')
                        }
                    }
                    /*{
                        label: 'Experimental',
                        onClick: () => {
                            navigate('/ticketMaster/experimentalPage')
                        }
                    }
                    {
                        label: 'Active Trader',
                        onClick: () => {
                            navigate('/activeTrader')
                        }
                    }*/
                ]}
            />
            <Navigation.Section
                title="Linking"
                items={[
                    {
                        label: 'Venue Linker',
                        onClick: () => {
                            navigate('/ticketMaster/venueLinker')
                        }
                    },
                    {
                        label: 'Event Linker',
                        onClick: () => {
                            navigate('/ticketMaster/eventLinker')
                        }
                    },
                    {
                        label: 'Custom Linker',
                        onClick: () => {
                            navigate('/ticketMaster/customLinker')
                        }
                    }
                ]}
            />

            {true && (
                <Navigation.Section
                    title="AXS"
                    items={[
                        {
                            label: 'Venue Browser',
                            onClick: () => {
                                navigate('/axs/venuebrowser')
                            }
                        },
                        {
                            label: 'Event Browser',
                            onClick: () => {
                                navigate('/axs/eventbrowser')
                            }
                        }
                    ]}
                />
            )}

            {true && (
                <Navigation.Section
                    title="STUBHUB"
                    items={[
                        {
                            label: 'Venue Browser',
                            onClick: () => {
                                navigate('/stubhub/venuebrowser')
                            }
                        },
                        {
                            label: 'Event Browser',
                            onClick: () => {
                                navigate('/stubhub/eventbrowser')
                            }
                        }
                    ]}
                />
            )}

            <Navigation.Section
                title="Ticket Evolution"
                items={[
                    {
                        label: 'Bulk Buying',
                        onClick: () => {
                            navigate('/ticketEvolution/bulkBuyer')
                        }
                    }
                ]}
            />
            {false && (
                <Navigation.Section
                    title="Tickets.com"
                    items={[
                        {
                            label: 'Event Search',
                            onClick: () => {
                                navigate('/tdc/search')
                            }
                        }
                    ]}
                />
            )}
            {false && (
                <Navigation.Section
                    title="Administrator"
                    items={[
                        {
                            label: 'Users',
                            onClick: () => {
                                navigate('/admin/users')
                            }
                        }
                    ]}
                />
            )}
            <Navigation.Section
                title="Account Settings"
                items={[
                    {
                        label: 'InLink',
                        onClick: () => {
                            navigate('/inLink')
                        }
                    },
                    {
                        label: 'LogOut',
                        onClick: () => {
                            // alert("LogOut");
                            logOut().then(() => {
                                navigate('/')
                            })
                        }
                    }
                ]}
            />

            <FooterHelp>
                <div>
                    {isLocalhost && (
                        <p
                            style={{
                                background: 'hsl(135, 50%, 60%)',
                                padding: '24px 12px',
                                margin: '24px 0px',
                                borderRadius: 0,
                                fontWeight: 800
                            }}
                        >
                            LOCALHOST
                        </p>
                    )}
                    <p>
                        <b>Database: </b>
                        {process.env.REACT_APP_DBNAME}
                    </p>
                    <p>
                        <b>Version: </b>
                        {process.env.REACT_APP_COMMITID}
                    </p>
                    <p>
                        <b>Build Date: </b>
                        {process.env.REACT_APP_BUILDDATE}
                    </p>
                    <p>
                        <b>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                    'https://maki.weturntix.com' +
                                    useLocation().pathname
                                }
                            >
                                Prod
                            </a>
                            &nbsp; &nbsp;
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                    'http://localhost:3000' +
                                    useLocation().pathname
                                }
                            >
                                Local
                            </a>
                        </b>
                    </p>
                </div>
            </FooterHelp>
        </Navigation>
    )

    const loadingMarkup = isLoading ? <Loading /> : null

    const skipToContentTarget = (
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
    )

    const actualPageMarkup = (
        <Page title="Account">
            <Layout>
                {skipToContentTarget}
                <Layout.AnnotatedSection
                    title="Account details"
                    description="Hi."
                >
                    <Card sectioned>
                        <FormLayout>
                            <TextField
                                label="Full name"
                                value={nameFieldValue}
                                onChange={handleNameFieldChange}
                                autoComplete="name"
                            />
                            <TextField
                                type="email"
                                label="Email"
                                value={emailFieldValue}
                                onChange={handleEmailFieldChange}
                                autoComplete="email"
                            />
                        </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )

    const modalMarkup = (
        <Modal
            open={modalActive}
            onClose={toggleModalActive}
            title="Contact support"
            primaryAction={{
                content: 'Send',
                onAction: toggleModalActive
            }}
        >
            <Modal.Section>
                <FormLayout>
                    <TextField
                        label="Subject"
                        value={supportSubject}
                        onChange={handleSubjectChange}
                        autoComplete="off"
                    />
                    <TextField
                        label="Message"
                        value={supportMessage}
                        onChange={handleMessageChange}
                        autoComplete="off"
                        multiline
                    />
                </FormLayout>
            </Modal.Section>
        </Modal>
    )

    const logo = {
        width: 124,
        topBarSource: logoPNG,
        accessibilityLabel: 'WeTurnTix'
    }

    const fullscreenToggle = !isActiveTrader && (
        <div
            className="subtle-btn"
            style={{
                fontSize: 20,
                position: 'fixed',
                right: 8,
                top: 8
            }}
            onClick={() => setIsFullscreen(!isFullscreen)}
        >
            {isFullscreen ? <BiExitFullscreen /> : <BiFullscreen />}
        </div>
    )

    const standardApp = (
        <div style={{ height: '500px' }}>
            <AppProvider
                i18n={{
                    Polaris: {
                        Avatar: {
                            label: 'Avatar',
                            labelWithInitials: 'Avatar with initials {initials}'
                        },
                        ContextualSaveBar: {
                            save: 'Save',
                            discard: 'Discard'
                        },
                        TextField: {
                            characterCount: '{count} characters'
                        },
                        TopBar: {
                            toggleMenuLabel: 'Toggle menu',

                            SearchField: {
                                clearButtonLabel: 'Clear',
                                search: 'Search'
                            }
                        },
                        Modal: {
                            iFrameTitle: 'body markup'
                        },
                        Frame: {
                            skipToContent: 'Skip to content',
                            navigationLabel: 'Navigation',
                            Navigation: {
                                closeMobileNavigationLabel: 'Close navigation'
                            }
                        }
                    }
                }}
            >
                <Frame
                    logo={logo}
                    topBar={topBarMarkup}
                    navigation={navigationMarkup}
                    showMobileNavigation={mobileNavigationActive}
                    onNavigationDismiss={toggleMobileNavigationActive}
                    skipToContentTarget={skipToContentRef.current}
                >
                    {contextualSaveBarMarkup}
                    {loadingMarkup}
                    {fullscreenToggle}
                    {children}
                    {toastMarkup}
                    {modalMarkup}
                </Frame>
            </AppProvider>
        </div>
    )

    const fullscreenApp = (
        <div style={{ height: '100%' }}>
            {fullscreenToggle}
            {children}
        </div>
    )

    if (isFullscreen) {
        return fullscreenApp
    } else {
        return standardApp
    }
}

export default AppFrame
